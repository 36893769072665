import React, { useEffect, useState } from 'react';
import {
  Stack,
  Grid,
  GridItem,
  Button,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Input,
  Text,
  Checkbox as ChakraCheckbox,
} from '@chakra-ui/react';
import * as CryptoJS from 'crypto-js';
import { Form, Formik } from 'formik';
import { minimumDate, stringToBoolean, isQualifiedLead, doctorInfoRequired } from '@helpers';
import { Checkbox } from '@components';

const validateForm = (values, noDoctor) => {
  const errors = {};
  if (!noDoctor) {
    const requiredFields = ['physician_name', 'clinic_name', 'clinic_zip', 'next_visit'];

    // Generic required fields
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });

    if (!values.physician_name) {
      errors.physician_name = 'Please enter your physician’s name';
    }

    if (!values.clinic_name) {
      errors.clinic_name = 'Please enter your clinic’s name';
    }

    if (!values.clinic_zip) {
      errors.clinic_zip = 'Please enter a valid ZIP Code';
    } else if (!/^\d{5}$/i.test(values.clinic_zip)) {
      errors.clinic_zip = 'Invalid ZIP Code';
    }
  }

  return errors;
};

const DoctorInfo = ({ data, setData }) => {
  const [isComplete, setIsComplete] = useState(false);
  const [noDoctorCheckboxSelected, setNoDoctorCheckboxSelected] = useState(false);

  const physicianRequired = doctorInfoRequired(data.due_date);

  const qualifiedLead = isQualifiedLead(data.due_date);

  useEffect(() => {
    if (isComplete) {
      const timeoutId = setTimeout(() => {
        if (window.self === window.top) {
          // If not in a frame, redirect to the thank-you page
          window.location.href = 'https://www.babypeek.com/thank-you';
        } else {
          // If in a frame, post a message to the parent window
          window.parent.postMessage('navigateToThankYou', '*');
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [isComplete]);

  const hashAndSendToGTM = (values) => {
    const { physician_name, clinic_name, clinic_zip } = values;
    const { first_name, last_name, email } = data;
    const hashedDoctorName = CryptoJS.SHA256(physician_name).toString(CryptoJS.enc.Hex);
    const hashedClinicName = CryptoJS.SHA256(clinic_name).toString(CryptoJS.enc.Hex);
    const hashedClinicZip = CryptoJS.SHA256(clinic_zip).toString(CryptoJS.enc.Hex);

    window.dataLayer.push({
      event: 'lead_created',
      hashed_doctor_name: hashedDoctorName,
      hashed_clinic_name: hashedClinicName,
      hashed_clinic_zip: hashedClinicZip,
    });

    if (qualifiedLead && values.understand_contact_requirement) {
      const hashedEmail = CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
      window.dataLayer.push({
        event: 'qualified_lead',
        first_name,
        last_name,
        email,
        hashed_email: hashedEmail,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        physician_name: data.physician_name || '',
        clinic_name: data.clinic_name || '',
        clinic_zip: data.clinic_zip || '',
        next_visit: data.next_visit || '',
        understand_contact_requirement:
          stringToBoolean(data.understand_contact_requirement) || false,
      }}
      validate={(values) => validateForm(values, noDoctorCheckboxSelected)}
      onSubmit={(values, { setSubmitting }) => {
        setData({ ...values, completed: true }); // Add completed flag to data object
        setSubmitting(false);
        hashAndSendToGTM(values);
        setIsComplete(true);
      }}
    >
      {({
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        touched,
        setFieldValue,
      }) => (
        <Stack
          as={Form}
          onSubmit={handleSubmit}
          gap={{ base: 20, sm: 40 }}
        >
          <Stack gap={{ base: 10, sm: 20 }}>
            <Text
              as='h1'
              textStyle='h1'
            >
              Share your doctor’s information
            </Text>
            <Text>Our team will reach out to unlock UNITY and BabyPeek for you.</Text>
            {!physicianRequired && (
              <ChakraCheckbox
                size='lg'
                type='checkbox'
                isChecked={noDoctorCheckboxSelected}
                iconColor='green'
                onChange={(e) => {
                  setNoDoctorCheckboxSelected(e.target.checked);
                }}
              >
                <Text
                  as='span'
                  fontSize={14}
                >
                  I do not have a doctor yet.
                </Text>
              </ChakraCheckbox>
            )}
          </Stack>
          <Grid
            gridTemplateColumns={{ base: '1fr', sm: 'repeat(2, 1fr)' }}
            rowGap={{ base: 5, sm: 10 }}
            columnGap={20}
          >
            {!noDoctorCheckboxSelected && (
              <>
                <GridItem>
                  <FormControl
                    isRequired
                    isInvalid={errors.physician_name && touched.physician_name}
                  >
                    <FormLabel requiredIndicator={null}>Physician Name</FormLabel>
                    <Input
                      type='text'
                      name='physician_name'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.physician_name}
                    />
                    <FormErrorMessage>{errors.physician_name}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isRequired
                    isInvalid={errors.clinic_name && touched.clinic_name}
                  >
                    <FormLabel requiredIndicator={null}>Clinic Name</FormLabel>
                    <Input
                      type='text'
                      name='clinic_name'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.clinic_name}
                    />
                    <FormErrorMessage>{errors.clinic_name}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    isRequired
                    isInvalid={errors.clinic_zip && touched.clinic_zip}
                  >
                    <FormLabel requiredIndicator={null}>Clinic ZIP Code</FormLabel>
                    <Input
                      type='string'
                      name='clinic_zip'
                      maxLength='5'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.clinic_zip}
                    />
                    <FormErrorMessage>{errors.clinic_zip}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel requiredIndicator={null}>Next Visit Date</FormLabel>
                    <Input
                      type='date'
                      name='next_visit'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.next_visit}
                      min={minimumDate}
                      sx={{
                        '::-webkit-date-and-time-value': {
                          textAlign: 'left',
                        },
                        height: '56px',
                      }}
                    />
                    <FormErrorMessage>{errors.next_visit}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              </>
            )}
            <GridItem colSpan={{ sm: 2 }}>
              <FormControl isRequired>
                <Checkbox
                  name='understand_contact_requirement'
                  label='I understand I will need to speak with the BabyPeek team, in order to have my doctor order the test for me.'
                  onChange={(e) =>
                    setFieldValue('understand_contact_requirement', e.target.checked)
                  }
                  isChecked={values.understand_contact_requirement}
                />
                <FormErrorMessage>{errors.understand_contact_requirement}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
          <Button
            type='submit'
            size={{ base: 'sm', sm: 'md' }}
            // makes button full width on screens < 480px
            width={{ base: '100%', sm: 'auto' }}
            alignSelf='flex-start'
            isLoading={isSubmitting}
          >
            Request Access
          </Button>
        </Stack>
      )}
    </Formik>
  );
};

export default DoctorInfo;
