import React from 'react';
import { Stack, Button, FormErrorMessage, FormControl, FormLabel, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { Checkbox, PhoneInputField } from '@components';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { hashPhoneNumber, stringToBoolean } from '@helpers';

const validateForm = (values) => {
  const errors = {};
  if (!values.phone_number) {
    errors.phone_number = 'Please enter a valid phone number';
  } else if (values.phone_number?.length !== 12 && !isPossiblePhoneNumber(values?.phone_number)) {
    errors.phone_number = 'Invalid phone number';
  }
  return errors;
};

const sendToGTM = (values) => {
  const phoneNum = values.phone_number;
  window.dataLayer.push({
    event: 'phone_submitted',
    phone: phoneNum,
    hashed_phone: hashPhoneNumber(phoneNum),
  });
};

const Phone = ({ data, setData, goToNextStep }) => (
  <Formik
    initialValues={{
      phone_number: data.phone_number || '',
      messaging_preference: stringToBoolean(data.messaging_preference) || false,
      agree_to_terms: stringToBoolean(data.agree_to_terms) || false,
    }}
    validate={(values) => validateForm(values)}
    onSubmit={(values, { setSubmitting }) => {
      setData(values);
      setSubmitting(false);
      sendToGTM(values);
      goToNextStep();
    }}
  >
    {({ errors, isSubmitting, handleSubmit, touched }) => (
      <Stack
        as={Form}
        onSubmit={handleSubmit}
        gap={{ base: 20, sm: 40 }}
      >
        <Stack gap={{ base: 10, sm: 20 }}>
          <Text
            as='h1'
            textStyle='h1'
          >
            Request access to BabyPeek, the only genetic test of its kind
          </Text>
          <Text>Let’s start with the basics. Where can we reach you?</Text>
        </Stack>
        <Stack gap={10}>
          <FormControl
            isRequired
            isInvalid={errors.phone_number && touched.phone_number}
          >
            <FormLabel requiredIndicator={null}>Phone Number</FormLabel>
            <PhoneInputField
              name='phone_number'
              label='Phone Number'
              country='US'
            />
            <FormErrorMessage>{errors.phone_number}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack gap={{ sm: 2 }}>
          <Stack>
            <FormControl>
              <Checkbox
                name='messaging_preference'
                label='I agree to receive text messages at the number provided. Message and data rates may apply.'
              />
            </FormControl>
          </Stack>
          <Stack>
            <FormControl isRequired>
              <Checkbox
                name='agree_to_terms'
                label='I agree to the <a href="https://billiontoone.com/privacy-policy/" target="_blank">Privacy Policy</a> and <a href="https://www.babypeek.com/messaging-terms-of-service" target="_blank">Terms</a>.'
              />
              <FormErrorMessage>{errors.agree_to_terms}</FormErrorMessage>
            </FormControl>
          </Stack>
        </Stack>
        <Button
          type='submit'
          alignSelf='flex-start'
          isLoading={isSubmitting}
          size={{ base: 'sm', sm: 'md' }}
          // makes button full width on screens < 480px
          width={{ base: '100%', sm: 'auto' }}
        >
          Continue
        </Button>
      </Stack>
    )}
  </Formik>
);

export default Phone;
